import { useState } from 'react'

import { Center, ScaleFade } from '@chakra-ui/react'
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'

const GuestPage = () => {
	const [show1, setShow1] = useState(true)
	const [show2, setShow2] = useState(false)
	const [visitorID, setVisitorID] = useState('')
	return (
		<Center minH="100vh" color="gray.50" px={['4', '8']} py="16" className="guest-container">
			{show1 && (
				<ScaleFade initialScale={0.8} in={show1}>
					<StepOne
						onSuccess={id => {
							setShow1(false)
							setShow2(true)
							setVisitorID(id)
						}}
					/>
				</ScaleFade>
			)}

			{show2 && (
				<ScaleFade initialScale={0.8} in={show2}>
					<StepTwo visitorID={visitorID} />
				</ScaleFade>
			)}
		</Center>
	)
}

export default GuestPage

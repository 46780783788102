import React from 'react'
import { Box, Heading, SimpleGrid, GridItem } from '@chakra-ui/react'

import { AdminLayout } from '@layouts'
import GuestBarChart from '@components/GuestBarChart'
import StatsCardGrid from './components/StatsCardGrid'
import GuestTable from '@components/GuestTable'
import { format, add, sub } from 'date-fns'
import { useQuery } from 'react-query'
import { fetchMeeting, visitorStatistic } from '@services/API'

const AdminPage = () => {
	const startDate = format(new Date(), 'yyyy-MM-dd 00:00:00')
	const endDate = format(
		add(new Date(), {
			days: 1,
		}),
		'yyyy-MM-dd 00:00:00'
	)

	const { data, isLoading } = useQuery(
		[
			'todaymeeting',
			{ expand: 'responses,scheduleHistories,visitor,employee', pageSize: 20, dateTime: `ge${startDate},le${endDate}` },
		],
		fetchMeeting,
		{
			refetchOnWindowFocus: false,
		}
	)

	const monthlyVisitor = useQuery(
		[
			'monthlyVisitor',
			{
				startDateTime: format(
					sub(new Date(), {
						months: 6,
					}),
					'yyyy-MM-dd 00:00:00'
				),
				totalData: 6,
				interval: 1,
				unit: 'months',
			},
		],
		visitorStatistic,
		{
			refetchOnWindowFocus: false,
		}
	)

	return (
		<AdminLayout title="Dashboard">
			<Box>
				<SimpleGrid spacing={6} columns={[1, 2, 2, 2, 4]}>
					<StatsCardGrid />
					<GridItem colSpan={[1, 2, 2, 2, 4]}>
						<Box bg="white" p={['4', null, '8']} borderRadius="xl" position="relative">
							<Heading as="h3" fontSize={['20px', null, '24px']} fontWeight="extrabold">
								Tamu Hari Ini
							</Heading>
							<GuestTable data={data} isLoading={isLoading} />
						</Box>
					</GridItem>
					<GridItem colSpan={[1, 2]}>
						{monthlyVisitor.isLoading ? null : (
							<GuestBarChart data={monthlyVisitor.data.data} type="month" title="Total Tamu Bulanan" />
						)}
					</GridItem>
					<GridItem colSpan={[1, 2]}>{/* <GuestBarChart /> */}</GridItem>
				</SimpleGrid>
			</Box>
		</AdminLayout>
	)
}

export default AdminPage

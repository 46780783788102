function formatString(str, max) {
	if (!str) return null
	if (Object.keys(str).length > max) {
		return str.substring(0, max) + ' ...'
	} else {
		return str
	}
}

export default formatString

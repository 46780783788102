import React, { Fragment } from 'react'
import StatsCard from '@components/StatsCard'
import { useQuery } from 'react-query'
import { fetchVisitorStatistic } from '@services/API'

const StatsCardGrid = () => {
	const { data, isLoading } = useQuery('VisitorStatistic', fetchVisitorStatistic)
	return (
		<Fragment>
			<StatsCard isLoading={isLoading} title="Tamu Hari Ini" count={data?.data.today} />
			<StatsCard isLoading={isLoading} title="Tamu Bulan Ini" count={data?.data.month} badgeColor="purple.400" />
			<StatsCard isLoading={isLoading} title="Tamu Minggu Ini" count={data?.data.week} badgeColor="teal.300" />
			<StatsCard isLoading={isLoading} title="Total Tamu Keseluruhan" count={data?.data.total} badgeColor="red.300" />
		</Fragment>
	)
}

export default StatsCardGrid

import { Box, Heading, Avatar, Text, Flex, Image, Divider } from '@chakra-ui/react'

import NavItem from '@components/NavItem'

import { DashboardIcon, GuestIcon, LogOutIcon } from '@components/svgIcons'
import { fetchAccount } from '@services/API'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import gravatar from 'gravatar'

const AdminLayout = ({ children, title }) => {
	const { data, isLoading } = useQuery('accountDetail', fetchAccount, {
		refetchOnWindowFocus: false,
	})

	const history = useHistory()
	return (
		<Box bg="white">
			<Box
				overflow="auto"
				w="64"
				h="full"
				position="fixed"
				shadow="xl"
				color="gray.700"
				display={{ base: 'none', lg: 'block' }}>
				<Flex alignItems="center" padding="8">
					<Image src="https://program.mitrasdudi.id/static/media/Mitras%20Dudi%20Logo.c6381063.png" w="10" />
					<Heading as="h4" fontWeight="black" textAlign="center" ml="6" fontSize="xl">
						Buku Tamu
					</Heading>
				</Flex>

				<Divider mb="7" />

				<Box textAlign="center">
					<Avatar src={gravatar.url(data?.data.email, { d: 'retro' })} size="xl" />
					<Text fontWeight="bold" fontSize="md" mt="2">
						{data?.data.email}
					</Text>
				</Box>

				<Flex mt="8" flexDirection="column">
					<Link to="/operator">
						<NavItem
							active={history.location.pathname.includes('/operator')}
							text="Dashboard"
							icon={<DashboardIcon />}
						/>
					</Link>
					<Link to="/all-guest">
						<NavItem
							active={history.location.pathname.includes('/all-guest')}
							text="Daftar Tamu"
							icon={<GuestIcon />}
						/>
					</Link>
					<a
						href="#"
						onClick={() => {
							localStorage.clear()
							window.location.href = '/'
						}}>
						<NavItem text="Keluar" icon={<LogOutIcon />} />
					</a>
				</Flex>
			</Box>
			<Box minH="100vh" bg="#F2F7FF" pb="32" ml={['0', null, null, '64']} pt="10" px={['6', null, '8']}>
				<Flex>
					<Heading as="h1" fontWeight="black" fontSize="3xl">
						{title}
					</Heading>
				</Flex>

				<Divider my="8" />
				{children}
			</Box>
		</Box>
	)
}

export default AdminLayout

import queryString from 'query-string'

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const { REACT_APP_OLD_API_URL } = process.env

const headers = {
	Role: 'Procurement-Officer',
}

if (localStorage.getItem('@user_token')) {
	headers.Authorization = localStorage.getItem('@user_token') ? 'Bearer ' + localStorage.getItem('@user_token') : null
}

export const fetchVisitor = async ({ queryKey }) => {
	// eslint-disable-next-line
	const [_key, query] = queryKey

	const res = await fetch(`${BASE_URL}/appointment/visitor?${queryString.stringify(query)}`)
	return res.json()
}

export const fetchAccount = async () => {
	const res = await fetch(`${REACT_APP_OLD_API_URL}/access/account`, {
		method: 'POST',
		headers,
	})
	return res.json()
}

export const fetchVisitorStatistic = async () => {
	const res = await fetch(`${BASE_URL}/appointment/visitor/statistic`)
	return res.json()
}

export const fetchMeeting = async ({ queryKey }) => {
	// eslint-disable-next-line
	const [_key, query] = queryKey

	const res = await fetch(`${BASE_URL}/appointment/meeting?${queryString.stringify(query)}`)
	return res.json()
}

export const visitorStatistic = async ({ queryKey }) => {
	// eslint-disable-next-line
	const [_key, query] = queryKey

	const res = await fetch(`${BASE_URL}/appointment/visitor/statistic/total?${queryString.stringify(query)}`)
	return res.json()
}

export const checkVisitor = async query => {
	const res = await fetch(`${BASE_URL}/appointment/visitor?${queryString.stringify(query)}`)
	return res.json()
}

export const createVisitor = async ({ name, institution, phone, identifierType, identifier, photo }) => {
	const formData = new FormData()

	formData.append('name', name)
	formData.append('institution', institution)
	formData.append('phone', phone)
	formData.append('identifierType', identifierType)
	formData.append('identifier', identifier)
	formData.append('photo', photo)

	const res = await fetch(`${BASE_URL}/appointment/visitor`, {
		method: 'POST',
		body: formData,
	})
	return res.json()
}

export const loginOperator = async ({ username, password }) => {
	const formData = new FormData()

	formData.append('username', username)
	formData.append('password', password)

	const res = await fetch(`${BASE_URL}/access/login`, {
		method: 'POST',
		body: formData,
		headers,
	})
	return res.json()
}

export const createMeeting = async ({ dateTime, employeeID, need, visitorID }) => {
	const formData = new FormData()

	formData.append('dateTime', dateTime)
	formData.append('employeeID', employeeID)
	formData.append('need', need)
	formData.append('visitorID', visitorID)

	const res = await fetch(`${BASE_URL}/appointment/meeting`, {
		method: 'POST',
		body: formData,
	})
	return res.json()
}

export const updateResponse = async ({ status, meetingID, note = '' }) => {
	const formData = new FormData()

	formData.append('status', status)
	formData.append('note', note)

	const res = await fetch(`${BASE_URL}/appointment/meeting/${meetingID}/update/response`, {
		method: 'POST',
		body: formData,
	})
	return res.json()
}

export const fetchEmployee = async ({ queryKey }) => {
	// eslint-disable-next-line
	const [_key, query] = queryKey
	const params = {
		...query,
		pageSize: 3,
	}

	const res = await fetch(`${BASE_URL}/employee?${queryString.stringify(params)}`, {
		method: 'GET',
	})
	return res.json()
}

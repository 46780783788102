import React from 'react'
import { Box, Heading, SimpleGrid, GridItem } from '@chakra-ui/react'

import { AdminLayout } from '@layouts'
import GuestTable from '@components/GuestTable'
import { useQuery } from 'react-query'
import { fetchMeeting } from '@services/API'

const AllGuestPage = () => {
	const { data, isLoading, refetch } = useQuery(
		['allMeeting', { expand: 'responses,scheduleHistories,visitor,employee', pageSize: 20 }],
		fetchMeeting,
		{
			refetchOnWindowFocus: false,
		}
	)

	return (
		<AdminLayout title="Semua Tamu">
			<Box>
				<SimpleGrid spacing={6} columns={[1, 2, 2, 2, 4]}>
					<GridItem colSpan={[1, 2, 2, 2, 4]}>
						<Box bg="white" p={['4', null, '8']} borderRadius="xl" position="relative">
							<Heading as="h3" fontSize={['20px', null, '24px']} fontWeight="extrabold">
								Semua Tamu
							</Heading>
							<GuestTable data={data} isLoading={isLoading} onSuccess={() => window.location.reload()} />
						</Box>
					</GridItem>
				</SimpleGrid>
			</Box>
		</AdminLayout>
	)
}

export default AllGuestPage

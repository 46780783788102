import { Text, Box, Flex } from '@chakra-ui/react'
import PropTypes from 'prop-types'

const IdentifierBox = ({ selected, onChange, value, text, disabled }) => {
	return (
		<Flex
			onClick={() => (disabled ? null : onChange(value))}
			position="relative"
			cursor={disabled ? 'not-allowed' : 'pointer'}
			padding="4"
			w="full"
			shadow={disabled ? 'none' : 'lg'}
			color="gray.800"
			borderRadius="md"
			justifyContent="center"
			alignItems="center"
			fontWeight="bold"
			border="2px"
			borderColor={selected ? 'pink.500' : 'gray.100'}
			transition="ease"
			transitionDuration="200ms"
			_hover={{
				shadow: 'none',
				transform: disabled ? 'none' : 'translateY(2px)',
			}}
			bg={selected ? 'pink.50' : 'gray.50'}>
			{selected && (
				<Box
					color="white"
					position="absolute"
					bg="pink.500"
					h="3"
					w="3"
					top="0"
					right="0"
					borderBottomLeftRadius="md"
					textAlign="center"
					fontSize="xx-small">
					✓
				</Box>
			)}

			<Text>{text}</Text>
		</Flex>
	)
}

IdentifierBox.propTypes = {
	selected: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
}

IdentifierBox.defaultProps = {
	selected: false,
	onChange: () => null,
	disabled: false,
}

export default IdentifierBox

import {
	Tr,
	Td,
	Avatar,
	Button,
	Flex,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	toast,
	FormControl,
	FormLabel,
	Textarea,
	Text,
} from '@chakra-ui/react'
import formatString from '@utils/formatString'
import { Fragment, useState, useRef } from 'react'
import { useMutation } from 'react-query'

import { updateResponse } from '@services/API'

const GuestTableRow = ({ meeting, onSuccess }) => {
	const [isOpenModalAccept, setIsOpenModalAccept] = useState(false)
	const [isOpenModalReject, setIsOpenModalReject] = useState(false)
	const acceptRef = useRef()
	const rejectRef = useRef()

	const [note, setNote] = useState('')

	const onCloseAccept = () => setIsOpenModalAccept(false)
	const onCloseReject = () => setIsOpenModalReject(false)

	const acceptMeeting = useMutation(updateResponse, {
		onSuccess: () => {
			onCloseAccept()
			onSuccess()
			toast({
				status: 'error',
				title: 'Berhasil terima tamu',
				position: 'bottom',
				duration: 3000,
				isClosable: true,
			})
		},
	})

	const rejecttMeeting = useMutation(updateResponse, {
		onSuccess: () => {
			onCloseReject()
			onSuccess()
			setNote('')
			toast({
				status: 'error',
				title: 'Berhasil tolak tamu',
				position: 'bottom',
				duration: 3000,
				isClosable: true,
			})
		},
	})

	const onAccept = async () => {
		acceptMeeting.mutate({
			status: '1',
			note: '',
			meetingID: meeting.ID,
		})
	}

	const onReject = async () => {
		rejecttMeeting.mutate({
			status: '0',
			note: '',
			meetingID: meeting.ID,
		})
	}

	return (
		<Fragment>
			<Tr>
				<Td>{meeting.visitor.phone}</Td>
				<Td>
					<Avatar name={meeting.visitor.name} src={meeting.visitor.photo} />
				</Td>
				<Td>{meeting.visitor.name}</Td>
				<Td>{meeting.visitor.institution}</Td>
				<Td>{meeting.visitor.name}</Td>
				<Td width="500px">{formatString(meeting.need, 100)}</Td>
				<Td
					fontWeight="bold"
					color={meeting.response ? (meeting.response.status === 1 ? 'green.300' : 'red.300') : 'orange.300'}>
					{meeting.response?.label ?? 'Menunggu'}
				</Td>

				<Td>
					{!!meeting.response ? (
						<Flex>-</Flex>
					) : (
						<Flex>
							<Button size="sm" colorScheme="red" onClick={() => setIsOpenModalReject(true)}>
								Tolak
							</Button>
							<Button size="sm" colorScheme="teal" ml="2" onClick={() => setIsOpenModalAccept(true)}>
								Terima
							</Button>
						</Flex>
					)}
				</Td>
			</Tr>

			<AlertDialog isOpen={isOpenModalAccept} leastDestructiveRef={acceptRef} onClose={onCloseAccept}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Terima Tamu
						</AlertDialogHeader>

						<AlertDialogBody>Yakin terima tamu {meeting.visitor.name} ?</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={acceptRef} onClick={onCloseAccept}>
								Batal
							</Button>
							<Button isLoading={acceptMeeting.isLoading} colorScheme="green" ml={3} onClick={onAccept}>
								Terima
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<AlertDialog isOpen={isOpenModalReject} leastDestructiveRef={rejectRef} onClose={onCloseReject}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Tolak Tamu
						</AlertDialogHeader>

						<AlertDialogBody>
							<Text fontWeight="bold"> Yakin tolak tamu {meeting.visitor.name} ?</Text>
							<br />
							<FormControl id="noteReject">
								<FormLabel color="gray.800">Alasan (opsional)</FormLabel>
								<Textarea
									fontWeight="medium"
									bg="gray.200"
									color="gray.700"
									border="none"
									name="note"
									type="text"
									placeholder="Masukan Alasan"
									value={note}
									onChange={e => setNote(e.target.value)}
								/>
							</FormControl>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={rejectRef} onClick={onCloseReject}>
								Batal
							</Button>
							<Button isLoading={rejecttMeeting.isLoading} colorScheme="green" ml={3} onClick={onReject}>
								Tolak
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Fragment>
	)
}

export default GuestTableRow

import { FormControl, FormLabel, FormErrorMessage, Input, Button, Box, SimpleGrid } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import IdentifierBox from '@components/IdentifierBox'
import { useState, useRef, useEffect } from 'react'
import { useMutation } from 'react-query'
import { checkVisitor } from '@services/API'

const formSchema = yup.object().shape({
	identifier: yup.string().required('Identifikasi tidak boleh kosong'),
})

const ValidateIDForm = ({ onValidate }) => {
	const [selectedID, setSelectedID] = useState('1')
	const [identifier, setIdentifier] = useState('1')
	const inputRef = useRef()

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(formSchema),
	})

	const checkIinitialVisitor = useMutation(checkVisitor, {
		onSuccess: ({ data: { list } }) => {
			onValidate(list, selectedID, identifier)
		},
	})

	const onChangeID = id => {
		setSelectedID(id)
		inputRef.current.focus()
	}

	const onSubmit = async data => {
		setIdentifier(data.identifier)
		await checkIinitialVisitor.mutate({ keyword: data.identifier, identifierType: selectedID, expand: 'identifiers' })
	}

	useEffect(() => {
		if (inputRef.current) {
			register(inputRef.current)
			inputRef.current.focus()
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Box w="full">
			<FormLabel color="gray.800" fontWeight="bold">
				Jenis Identitas <span style={{ color: 'red' }}>*</span>
			</FormLabel>
			<SimpleGrid columns={[1, null, 3]} gap={4}>
				<IdentifierBox text="KTP" value="1" selected={selectedID === '1'} onChange={onChangeID} />
				<IdentifierBox text="SIM" value="2" selected={selectedID === '2'} onChange={onChangeID} />
				<IdentifierBox text="Pers ID" value="3" selected={selectedID === '3'} onChange={onChangeID} />
			</SimpleGrid>
			<FormControl id="guestPhoneNumber" isInvalid={!!errors?.identifier} mt="4">
				<FormLabel color="gray.800" fontWeight="bold">
					Identitas <span style={{ color: 'red' }}>*</span>
				</FormLabel>
				<Input
					fontWeight="medium"
					bg="gray.200"
					ref={inputRef}
					color="gray.700"
					border="none"
					type="text"
					placeholder="Masukkan Nomor Identitas"
					name="identifier"
				/>
				<FormErrorMessage>{errors?.identifier?.message}</FormErrorMessage>
			</FormControl>

			<Button
				marginTop="32px !important"
				onClick={handleSubmit(onSubmit)}
				isLoading={checkIinitialVisitor.isLoading}
				loadingText="Memproses"
				bg="pink.400"
				w="full"
				size="lg"
				_hover={{ bg: 'pink.600' }}
				_active={{
					bg: 'pink.500',
				}}
				_focus={{
					outline: 'none',
				}}>
				Lanjut
			</Button>
		</Box>
	)
}

export default ValidateIDForm

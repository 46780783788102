import {
	Heading,
	VStack,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Button,
	ScaleFade,
	SimpleGrid,
	Image,
	Text,
	Flex,
	Box,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Fragment, useEffect, useState } from 'react'
import { createVisitor } from '@services/API'
import { useMutation } from 'react-query'
import ValidateIDForm from './ValidateIDForm'
import mapValues from 'lodash/mapValues'
import IdentifierBox from '@components/IdentifierBox'
import WebcamCapture from '@components/WebcamCapture'

let defaultValues = {
	name: '',
	institution: '',
	phone: '',
	identifierType: '1',
	identifier: '',
	photo: null,
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const formSchema = yup.object().shape({
	name: yup.string().required('Nama tidak boleh kosong'),
	institution: yup.string().required('Lembaga tidak boleh kosong'),
	phone: yup.string().required('Nomor Hp tidak boleh kosong').matches(phoneRegExp, 'Nomor Hp tidak valid'),
	identifierType: yup.string().required('Jenis Identitas tidak boleh kosong'),
	identifier: yup.string().required('Nomor Identitas tidak boleh kosong'),
})

const StepOne = ({ onSuccess }) => {
	const [step, setStep] = useState(1)
	const [currentVisitor, setCurrentVisitor] = useState(null)
	const [selectedID, setSelectedID] = useState('1')
	const [previewPhoto, setPreviewPhoto] = useState(null)

	const { register, handleSubmit, errors, setValue } = useForm({
		resolver: yupResolver(formSchema),
		shouldUnregister: false,
		defaultValues,
	})

	const registerVisitor = useMutation(createVisitor, {
		onSuccess: ({ data }) => {
			onSuccess(data.ID)
		},
	})

	const onSubmit = async data => {
		if (currentVisitor !== null) {
			onSuccess(currentVisitor?.visitorID)
		} else {
			await registerVisitor.mutate(data)
		}
	}

	const onValidate = (data, identifierType, identifier) => {
		setStep(2)
		if (data.length > 0) {
			const current = data[0]
			const temp = {
				visitorID: current.ID,
				name: current.name,
				institution: current.institution,
				phone: current.phone,
				identifierType: `${current.identifiers[0].type}`,
				identifier: current.identifiers[0].identifier,
			}
			defaultValues = temp
			mapValues(defaultValues, (value, key) => setValue(key, value))
			setCurrentVisitor(temp)
			if (!!current.photo) {
				setPreviewPhoto(current.photo)
			}
		} else {
			setSelectedID(identifierType)
			setValue('identifier', identifier)
			setValue('identifierType', identifierType)
		}
	}

	useEffect(() => {
		register('photo')
		// eslint-disable-next-line
	}, [])

	return (
		<VStack spacing={['10', '10', '16']}>
			<Heading as="h2" size="2xl" textAlign="center" maxW="xl">
				Masukkan informasi Anda terlebih dahulu
			</Heading>

			<VStack spacing="4" maxW="4xl" w="100%" bg="gray.50" padding="8" borderRadius="xl" shadow="2xl">
				{step === 1 && <ValidateIDForm onValidate={onValidate} />}

				{step === 2 && (
					<Fragment>
						<FormControl id="guestidentifierType" isInvalid={!!errors?.identifierType}>
							<FormLabel color="gray.800">
								Jenis Identitas <span style={{ color: 'red' }}>*</span>
							</FormLabel>
							<SimpleGrid columns={[1, null, 3]} gap={4}>
								<IdentifierBox
									text="KTP"
									value="1"
									selected={selectedID === '1'}
									onChange={id => {
										setValue('identifierType', id)
										setSelectedID(id)
									}}
								/>
								<IdentifierBox
									text="SIM"
									value="2"
									selected={selectedID === '2'}
									onChange={id => {
										setValue('identifierType', id)
										setSelectedID(id)
									}}
								/>
								<IdentifierBox
									text="Pers ID"
									value="3"
									selected={selectedID === '3'}
									onChange={id => {
										setValue('identifierType', id)
										setSelectedID(id)
									}}
								/>
							</SimpleGrid>
							<FormErrorMessage>{errors?.identifierType?.message}</FormErrorMessage>
						</FormControl>

						<FormControl id="guestIdentifier" isInvalid={!!errors?.identifier} mt="4">
							<FormLabel color="gray.800">
								No. Identitas <span style={{ color: 'red' }}>*</span>
							</FormLabel>
							<Input
								fontWeight="medium"
								bg="gray.200"
								ref={register}
								color="gray.700"
								border="none"
								name="identifier"
								type="text"
								placeholder="Masukan No. Identitas"
							/>
							<FormErrorMessage>{errors?.identifier?.message}</FormErrorMessage>
						</FormControl>

						<FormControl id="guestPhoneNumber" isInvalid={!!errors?.phone} mt="4">
							<FormLabel color="gray.800">
								No. Hp<span style={{ color: 'red' }}>*</span>
							</FormLabel>
							<Input
								fontWeight="medium"
								bg="gray.200"
								ref={register}
								color="gray.700"
								border="none"
								type="number"
								name="phone"
								placeholder="No. Hp"
							/>
							<FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
						</FormControl>

						<ScaleFade in={true} initialScale={0.8} style={{ width: '100%' }}>
							<FormControl id="guestName" isInvalid={!!errors?.name}>
								<FormLabel color="gray.800">
									Nama <span style={{ color: 'red' }}>*</span>
								</FormLabel>
								<Input
									fontWeight="medium"
									bg="gray.200"
									ref={register}
									color="gray.700"
									border="none"
									name="name"
									type="text"
									placeholder="Nama Anda"
								/>
								<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
							</FormControl>

							<FormControl id="guestInstitution" isInvalid={!!errors?.institution} mt="4">
								<FormLabel color="gray.800">
									Institusi <span style={{ color: 'red' }}>*</span>
								</FormLabel>
								<Input
									fontWeight="medium"
									bg="gray.200"
									ref={register}
									color="gray.700"
									border="none"
									name="institution"
									type="text"
									placeholder="Masukan Institusi Anda"
								/>
								<FormErrorMessage>{errors?.institution?.message}</FormErrorMessage>
							</FormControl>

							{currentVisitor === null ? (
								<FormControl id="guesttPhoto" isInvalid={!!errors?.photo} mt="4">
									<FormLabel>
										<Text color="gray.800">
											Foto <small>optional</small>
										</Text>

										<Flex
											_hover={{
												borderColor: 'pink.400',
											}}
											direction="column"
											transition="ease"
											transitionDuration="200ms"
											cursor="pointer"
											w="full"
											border="2px"
											borderColor="gray.300"
											rounded="lg"
											borderStyle="dashed"
											py={!previewPhoto ? '6' : '0'}
											px="6"
											alignItems="center"
											bg="gray.200"
											justifyContent="center">
											{!previewPhoto ? (
												<WebcamCapture
													onCapture={(src, file) => {
														setPreviewPhoto(src)
														setValue('photo', file)
													}}
												/>
											) : (
												<Box p="8">
													<Image src={previewPhoto} width="300px" height="300px" objectFit="cover" />
													<Button
														marginTop="32px !important"
														onClick={() => {
															setPreviewPhoto(null)
															setValue('photo', null)
														}}
														loadingText="Memproses"
														bg="pink.500"
														w="full"
														size="lg"
														_hover={{ bg: 'pink.600' }}
														_active={{
															bg: 'pink.500',
														}}
														_focus={{
															outline: 'none',
														}}>
														Ulangi
													</Button>
												</Box>
											)}
										</Flex>
									</FormLabel>

									<FormErrorMessage>{errors?.photo?.message}</FormErrorMessage>
								</FormControl>
							) : (
								<Flex
									_hover={{
										borderColor: 'pink.400',
									}}
									direction="column"
									transition="ease"
									transitionDuration="200ms"
									w="full"
									border="2px"
									borderColor="gray.300"
									rounded="lg"
									mt="8"
									borderStyle="dashed"
									py={!previewPhoto ? '6' : '0'}
									px="6"
									alignItems="center"
									bg="gray.200"
									justifyContent="center">
									{!previewPhoto ? (
										<Image src="/assets/images/photos_object.svg" width="100px" opacity="0.5" />
									) : (
										<Image src={previewPhoto} width="300px" height="300px" objectFit="cover" />
									)}
								</Flex>
							)}
						</ScaleFade>

						<Button
							marginTop="32px !important"
							onClick={handleSubmit(onSubmit)}
							isLoading={registerVisitor.isLoading}
							loadingText="Memproses"
							bg="pink.500"
							w="full"
							size="lg"
							_hover={{ bg: 'pink.600' }}
							_active={{
								bg: 'pink.500',
							}}
							_focus={{
								outline: 'none',
							}}>
							Lanjut
						</Button>
					</Fragment>
				)}
			</VStack>
		</VStack>
	)
}

export default StepOne

import Chart from 'react-apexcharts'
import {
	Box,
	Heading,
	IconButton,
	Flex,
	MenuButton,
	MenuList,
	Menu,
	MenuOptionGroup,
	MenuItemOption,
} from '@chakra-ui/react'
import { OptionIcon } from './svgIcons'
import { format } from 'date-fns'

const GuestBarChart = ({ data, type, title }) => {
	const state = {
		options: {
			chart: {
				id: 'basic-bar',
				type: 'bar',
				toolbar: {
					show: false,
				},
			},
			xaxis: {
				categories: Object.keys(data).map(date => format(new Date(date), type === 'month' ? 'MMMM' : 'dd MMM')),
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '40%',
					endingShape: 'rounded',
				},
			},
		},
		series: [
			{
				name: 'series-1',
				data: Object.values(data),
			},
		],
	}

	return (
		<Box bg="white" borderRadius="xl" p="8">
			<Flex alignItems="center" justifyContent="space-between" mb="4">
				<Heading as="h3" fontSize={['20px', null, '24px']} fontWeight="extrabold">
					{title}
				</Heading>

				<Menu offset={[-180, 10]}>
					{/* <MenuButton as={IconButton} aria-label="Options" icon={<OptionIcon />} /> */}
					<MenuList>
						<MenuOptionGroup type="radio" value="monthly">
							<MenuItemOption value="weekly">Mingguan</MenuItemOption>
							<MenuItemOption value="monthly">Bulanan</MenuItemOption>
						</MenuOptionGroup>
					</MenuList>
				</Menu>
			</Flex>
			<Chart options={state.options} series={state.series} type="bar" height="400px" />
		</Box>
	)
}

export default GuestBarChart

import React, { Fragment } from 'react'
import {
	Box,
	Avatar,
	Text,
	DrawerCloseButton,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	useDisclosure,
	Flex,
	Button,
	Divider,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	toast,
	FormControl,
	FormLabel,
	Textarea,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import idLocal from 'date-fns/locale/id'
import { useMutation } from 'react-query'
import { updateResponse } from '@services/API'

const GuestCard = ({ meeting, index, onSuccess }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [isOpenModalAccept, setIsOpenModalAccept] = React.useState(false)
	const [isOpenModalReject, setIsOpenModalReject] = React.useState(false)
	const [note, setNote] = React.useState('')

	const acceptRef = React.useRef()
	const rejectRef = React.useRef()
	const onCloseAccept = () => setIsOpenModalAccept(false)
	const onCloseReject = () => setIsOpenModalReject(false)

	const formattedDate = format(new Date(meeting.schedule.dateTime), 'EEEE, dd MMM yyyy |  HH:mm', { locale: idLocal })

	const acceptMeeting = useMutation(updateResponse)

	const rejecttMeeting = useMutation(updateResponse, {
		onSuccess: () => {
			onCloseReject()
			onSuccess()
			setNote('')
			toast({
				status: 'error',
				title: 'Berhasil tolak tamu',
				position: 'bottom',
				duration: 3000,
				isClosable: true,
			})
		},
	})

	const onAccept = async () => {
		acceptMeeting.mutate(
			{
				status: '1',
				note: '',
				meetingID: meeting.ID,
			},
			{
				onSuccess: () => {
					onCloseAccept()
					onSuccess()
					toast({
						status: 'error',
						title: 'Berhasil terima tamu',
						position: 'bottom',
						duration: 3000,
						isClosable: true,
					})
				},
			}
		)
	}
	const onReject = async () => {
		rejecttMeeting.mutate({
			status: '0',
			note: '',
			meetingID: meeting.ID,
		})
	}
	return (
		<>
			<Box w="full" p="4" borderRadius="lg" border="2px" borderColor="gray.100" mt={index > 0 ? '2' : '0'}>
				<Flex alignItems="center" justifyContent="space-between">
					<Box flex="1" overflow="hidden">
						<Text fontWeight="semibold">{meeting.visitor.name}</Text>
						<Text>{meeting.visitor.institution}</Text>
						<Text color={meeting.response ? (meeting.response.status === 1 ? 'green.300' : 'red.300') : 'orange.300'}>
							{meeting.response?.label ?? 'Menunggu'}
						</Text>
					</Box>
					<Avatar name={meeting.visitor.name} src={meeting.visitor.photo} ml="2" />
				</Flex>

				<Divider mt="4" />

				<Flex mt="2" flexDirection="column">
					<Box overflow="hidden">
						<Text fontWeight="medium">{meeting.employee.name}</Text>
					</Box>
					<Box overflow="hidden">
						<Text>{formattedDate}</Text>
					</Box>
				</Flex>

				<Flex alignItems="center" justifyContent="space-between" mt="4">
					{!!meeting.response ? null : (
						<Fragment>
							<Button size="sm" onClick={() => setIsOpenModalReject(true)}>
								Tolak
							</Button>
							<Button size="sm" colorScheme="teal" onClick={() => setIsOpenModalAccept(true)}>
								Terima
							</Button>
						</Fragment>
					)}
					<Button size="sm" onClick={onOpen} colorScheme="blue">
						Detail
					</Button>
				</Flex>
			</Box>

			<AlertDialog isOpen={isOpenModalAccept} leastDestructiveRef={acceptRef} onClose={onCloseAccept}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Terima Tamu
						</AlertDialogHeader>

						<AlertDialogBody>Yakin terima tamu {meeting.visitor.name} ?</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={acceptRef} onClick={onCloseAccept}>
								Batal
							</Button>
							<Button colorScheme="green" isLoading={acceptMeeting.isLoading} ml={3} onClick={onAccept}>
								Terima
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<AlertDialog isOpen={isOpenModalReject} leastDestructiveRef={rejectRef} onClose={onCloseReject}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Tolak Tamu
						</AlertDialogHeader>

						<AlertDialogBody>
							<Text fontWeight="bold"> Yakin tolak tamu {meeting.visitor.name} ?</Text>
							<br />
							<FormControl id="noteReject">
								<FormLabel color="gray.800">Alasan (opsional)</FormLabel>
								<Textarea
									fontWeight="medium"
									bg="gray.200"
									color="gray.700"
									border="none"
									name="note"
									type="text"
									placeholder="Masukan Alasan"
									value={note}
									onChange={e => setNote(e.target.value)}
								/>
							</FormControl>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={rejectRef} onClick={onCloseReject}>
								Batal
							</Button>
							<Button isLoading={rejecttMeeting.isLoading} colorScheme="green" ml={3} onClick={onReject}>
								Tolak
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay>
					<DrawerContent borderTopRadius="md">
						<DrawerCloseButton />
						<DrawerHeader>{meeting.visitor.name}</DrawerHeader>
						<DrawerBody>
							<Box>
								<Text fontWeight="bold">Ingin bertemu dengan :</Text>
								<Text>{meeting.employee.name}</Text>
								<Text fontWeight="bold" mt="2">
									Pada tanggal :
								</Text>
								<Text>{formattedDate}</Text>
								<Text fontWeight="bold" mt="2">
									Dengan alasan :
								</Text>
								<Text>{meeting.need}</Text>
							</Box>
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		</>
	)
}

export default GuestCard

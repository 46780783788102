import { Text, Box, Flex, Image } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import gravatar from 'gravatar'

const EmployeeSelectBox = ({ selected, onChange, value, disabled, employee }) => {
	return (
		<Flex
			direction="column"
			onClick={() => (disabled ? null : onChange(value))}
			position="relative"
			cursor={disabled ? 'not-allowed' : 'pointer'}
			w="full"
			overflow="hidden"
			shadow={disabled ? 'none' : 'lg'}
			color="gray.800"
			borderRadius="lg"
			justifyContent="center"
			alignItems="center"
			fontWeight="bold"
			border="2px"
			borderColor={selected ? 'pink.500' : 'gray.100'}
			transition="ease"
			transitionDuration="200ms"
			_hover={{
				shadow: 'none',
				transform: disabled ? 'none' : 'translateY(2px)',
			}}
			bg={selected ? 'pink.50' : 'gray.50'}>
			{selected && (
				<Box
					color="white"
					position="absolute"
					bg="pink.500"
					h="3"
					w="3"
					top="0"
					right="0"
					borderBottomLeftRadius="md"
					textAlign="center"
					fontSize="xx-small">
					✓
				</Box>
			)}
			<Box width="full">
				<Image
					src={!!employee.avatar ? employee.avatar : gravatar.url(employee.email, { d: 'retro' })}
					width="full"
					h="100px"
					objectFit="cover"
				/>
			</Box>

			<Box py="4">
				<Text color="gray.500">{employee.name}</Text>
			</Box>
		</Flex>
	)
}

EmployeeSelectBox.propTypes = {
	selected: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
}

EmployeeSelectBox.defaultProps = {
	selected: false,
	onChange: () => null,
	disabled: false,
}

export default EmployeeSelectBox

import {
	Heading,
	VStack,
	Center,
	FormControl,
	FormLabel,
	Input,
	Button,
	FormErrorMessage,
	useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { loginOperator } from '@services/API'

const formSchema = yup.object().shape({
	username: yup.string().email('Email Salah').required('Email tidak boleh kosong'),
	password: yup.string().required('Password tidak boleh kosong'),
})

const LoginPage = () => {
	const toast = useToast()

	const { register, errors, handleSubmit } = useForm({
		resolver: yupResolver(formSchema),
		mode: 'onChange',
	})

	const { isLoading, mutate } = useMutation(loginOperator, {
		onSuccess: response => {
			// window.localStorage
			if (response.code === 200) {
				if (response.data.token) {
					localStorage.setItem('@user_token', response.data.token)
					window.location.href = '/operator'
				}
			} else if (response.code === 422) {
				toast({
					status: 'error',
					title: response.message,
					position: 'bottom',
					duration: 3000,
					isClosable: true,
				})
			}
		},
	})

	const onSubmit = data => {
		mutate(data)
	}
	return (
		<Center minH="100vh" color="gray.50" px={['4', '8']} py="16" className="guest-container">
			<VStack spacing={['10', '10', '16']}>
				<Heading as="h2" size="2xl" textAlign="center" maxW="xl">
					Selamat Datang
				</Heading>

				<VStack spacing="4" maxW="container.md" w="100%" bg="gray.50" padding="8" borderRadius="xl" shadow="2xl">
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
						<FormControl id="email" isInvalid={!!errors?.email} mt="4">
							<FormLabel color="gray.800">
								Email <span style={{ color: 'red' }}>*</span>
							</FormLabel>
							<Input
								w="full"
								fontWeight="medium"
								bg="gray.200"
								ref={register}
								color="gray.700"
								border="none"
								name="username"
								type="email"
								placeholder="Masukan Email"
							/>
							<FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
						</FormControl>
						<FormControl id="password" isInvalid={!!errors?.password} mt="4">
							<FormLabel color="gray.800">
								Password <span style={{ color: 'red' }}>*</span>
							</FormLabel>
							<Input
								w="full"
								fontWeight="medium"
								bg="gray.200"
								ref={register}
								color="gray.700"
								border="none"
								name="password"
								type="password"
								placeholder="Masukan Password"
							/>
							<FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
						</FormControl>

						<Button
							type="submit"
							marginTop="32px !important"
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoading}
							loadingText="Memproses"
							bg="pink.500"
							w="full"
							size="lg"
							_hover={{ bg: 'pink.600' }}
							_active={{
								bg: 'pink.500',
							}}
							_focus={{
								outline: 'none',
							}}>
							Masuk
						</Button>
					</form>
				</VStack>
			</VStack>
		</Center>
	)
}

export default LoginPage

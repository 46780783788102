import React from 'react'
import { Box, Table, Thead, Tbody, Tr, Td, Th, Skeleton, SkeletonCircle, SimpleGrid } from '@chakra-ui/react'
import GuestCard from './GuestCard'
import GuestTableRow from './GuestTableRow'

const GuestTable = ({ data, isLoading, onSuccess }) => {
	return (
		<Box overflow={['unset', null, null, 'auto']}>
			<Box bg="white" minW="1200px" display={['none', null, null, 'block']}>
				<Table variant="striped" mt="8" minW="lg" className="custom-table">
					<Thead>
						<Tr>
							<Th>No. Hp</Th>
							<Th>Foto</Th>
							<Th>Nama</Th>
							<Th>Institusi</Th>
							<Th>Karyawan</Th>
							<Th id="table-description">Keperluan</Th>
							<Th>Status</Th>
							<Th id="table-description">Aksi</Th>
						</Tr>
					</Thead>

					<Tbody>
						{isLoading ? (
							<Tr>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<SkeletonCircle h="48px" w="48px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" my="2" />
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
								<Td>
									<Skeleton startColor="gray.500" endColor="gray.50" height="14px" w="100px" />
								</Td>
							</Tr>
						) : (
							data.data.list.map(meeting => (
								<GuestTableRow onSuccess={onSuccess} meeting={meeting} key={`meeting-${meeting.ID}`} />
							))
						)}
					</Tbody>
				</Table>
			</Box>

			<SimpleGrid columns={1} mt="4" display={['block', null, null, 'none']}>
				{isLoading ? (
					<>
						<Skeleton startColor="gray.500" endColor="gray.50" height="80px" w="full" rounded="md" />
						<Skeleton startColor="gray.500" endColor="gray.50" height="80px" w="full" rounded="md" my="2" />
						<Skeleton startColor="gray.500" endColor="gray.50" height="80px" w="full" rounded="md" />
					</>
				) : (
					data.data.list.map((meeting, index) => (
						<GuestCard onSuccess={onSuccess} meeting={meeting} key={`meetingCard-${meeting.ID}`} index={index} />
					))
				)}
			</SimpleGrid>
		</Box>
	)
}

export default GuestTable

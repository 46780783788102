import React from 'react'
import { Box, Text, Flex, Center, Skeleton } from '@chakra-ui/react'
import { GuestIcon } from '@components/svgIcons'
import PropTypes from 'prop-types'

const StatsCard = ({ icon, title, count, badgeColor, isLoading }) => {
	return isLoading ? (
		<Skeleton h="36" w="full" borderRadius="lg" />
	) : (
		<Flex bg="white" p="8" borderRadius="lg" position="relative" overflow="hidden" alignItems="center">
			<Center bg={badgeColor} h="16" w="16" borderRadius="lg">
				{icon}
			</Center>

			<Box ml="4">
				<Text fontSize={['sm', 'md', 'lg']} fontWeight="bold" color="gray.500">
					{title}
				</Text>
				<Text fontSize={['2xl', '2xl', '3xl']} fontWeight="bold">
					{count}
				</Text>
			</Box>
		</Flex>
	)
}

StatsCard.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string.isRequired,
	count: PropTypes.string,
	badgeColor: PropTypes.string,
}

StatsCard.defaultProps = {
	icon: <GuestIcon color="#FFF" size={42} />,
	badgeColor: 'pink.400',
	count: '0',
}

export default React.memo(StatsCard)

import { Button } from '@chakra-ui/button'
import dataURLtoFile from '@utils/datURLtoFile'
import React from 'react'
import Webcam from 'react-webcam'

const videoConstraints = {
	width: 720,
	height: 720,
	facingMode: 'user',
}

const WebcamCapture = ({ onCapture = () => null }) => {
	const webcamRef = React.useRef(null)

	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		const srcToFile = dataURLtoFile(imageSrc, 'photo]')
		onCapture(imageSrc, srcToFile)
		// eslint-disable-next-line
	}, [webcamRef])

	return (
		<>
			<Webcam
				audio={false}
				height={300}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				width={300}
				videoConstraints={videoConstraints}
			/>
			<Button
				marginTop="32px !important"
				onClick={capture}
				loadingText="Memproses"
				bg="pink.500"
				w="full"
				size="lg"
				_hover={{ bg: 'pink.600' }}
				_active={{
					bg: 'pink.500',
				}}
				_focus={{
					outline: 'none',
				}}>
				Ambil foto
			</Button>
		</>
	)
}

export default WebcamCapture

import { Fragment } from 'react'

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import HomePage from '@pages/Home'
import GuestPage from '@pages/Guest'
import OperatorPage, { AllGuestPage } from '@pages/Operator'
import { Login } from '@pages/Auth'

import './style.css'
const App = () => {
	return (
		<Router>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/guest" component={GuestPage} />
			{localStorage.getItem('@user_token') ? (
				<Fragment>
					<Route exact path="/operator" component={OperatorPage} />
					<Route exact path="/all-guest" component={AllGuestPage} />
				</Fragment>
			) : (
				<Fragment>
					<Route exact path="/login" component={Login} />
					<Route exact path="/operator" render={() => <Redirect to="/login" />} />
				</Fragment>
			)}
		</Router>
	)
}

export default App

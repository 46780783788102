import { Flex, Box, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

const NavItem = ({ active, icon, text, ...props }) => {
	return (
		<Box {...props}>
			<Flex
				transition="ease"
				transitionDuration="200ms"
				filter={active ? 'none' : 'grayscale(100%)'}
				_hover={{
					bg: 'cyan.50',
					filter: 'none',
				}}
				direction="row"
				alignItems="center"
				px="4"
				py="2"
				cursor="pointer"
				borderRight={active ? '4px' : 'none'}
				borderColor="cyan.400"
				bg="transparent"
				mb="4">
				<Box mb="1">{icon}</Box>
				<Text fontWeight="bold" ml="4" color="gray.700" fontSize="md">
					{text}
				</Text>
			</Flex>
		</Box>
	)
}

NavItem.propTypes = {
	active: PropTypes.bool,
	icon: PropTypes.element.isRequired,
	text: PropTypes.string.isRequired,
}
NavItem.defaultProps = {
	active: false,
}

export default NavItem

import { useCallback, useState, useRef, useEffect } from 'react'
import {
	Heading,
	VStack,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Button,
	SimpleGrid,
	Textarea,
	Input,
	Skeleton,
	Box,
	Image,
	Text,
	useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SELECT_EMPLOYEE } from '@utils/constants'
import EmployeeSelectBox from '@components/EmployeeSelectBox'
import debounce from 'lodash.debounce'
import { useMutation, useQuery } from 'react-query'
import { createMeeting, fetchEmployee } from '@services/API'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import { useHistory } from 'react-router'

const formSchema = yup.object().shape({
	employeeID: yup.string().required('Anda Harus Memilih Karyawan'),
	need: yup.string().required('Kebutuhan tidak boleh kosong'),
})

const StepTwo = ({ visitorID }) => {
	const [keyword, setKeyword] = useState('')
	const [keywordDisplay, setKeywordDisplay] = useState('')
	const [selectedEmployee, setSelectedEmployee] = useState('')
	const [date, changeDate] = useState(new Date())
	const inputRef = useRef()
	const toast = useToast()
	const history = useHistory()

	const employeeList = useQuery([SELECT_EMPLOYEE, { keyword }], fetchEmployee, {
		refetchOnWindowFocus: false,
	})

	const { register, handleSubmit, errors, setValue } = useForm({
		resolver: yupResolver(formSchema),
		defaultValues: {
			employeeID: '',
			need: '',
			dateTime: '',
			visitorID,
		},
	})

	const useCreateMeeting = useMutation(createMeeting, {
		onSuccess: () => {
			toast({
				status: 'success',
				title: 'Berhasil Membuat Janji',
				position: 'bottom',
				description: 'Silahkan tunggu respon dari karyawan',
				duration: 9000,
				isClosable: true,
			})
			history.push('/')
		},
	})

	const onSubmit = data => {
		useCreateMeeting.mutate(data)
	}

	// eslint-disable-next-line
	const onSearch = useCallback(
		debounce(val => {
			setKeyword(val)
		}, 500),

		[]
	)

	useEffect(() => {
		if (inputRef.current) {
			register(inputRef.current)
			register('employeeID')
			register('dateTime')
			register('visitorID')
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setValue('visitorID', visitorID)
		// eslint-disable-next-line
	}, [visitorID])

	useEffect(() => {
		setValue('dateTime', format(date, 'yyyy-MM-dd HH:mm:ss'))
		// eslint-disable-next-line
	}, [date])

	return (
		<VStack spacing={['10', '10', '16']}>
			<Heading as="h2" size="xl" textAlign="center" maxW="xl">
				Pilih Karyawan yang ingin di temui
			</Heading>

			<VStack spacing="4" maxW="4xl" w="100%" bg="gray.50" padding="8" borderRadius="xl" shadow="2xl">
				<FormControl id="guestName" isInvalid={!!errors?.name}>
					<FormLabel color="gray.800">
						Cari Karyawan <span style={{ color: 'red' }}>*</span>
					</FormLabel>
					<Input
						fontWeight="medium"
						bg="gray.200"
						color="gray.700"
						border="none"
						name="keyword"
						type="text"
						value={keywordDisplay}
						placeholder="Cari Nama Karyawan"
						onChange={event => {
							onSearch(event.target.value)
							setKeywordDisplay(event.target.value)
						}}
					/>
				</FormControl>

				{employeeList.isLoading ? (
					<SimpleGrid columns={[1, null, 3]} gap={4} w="full">
						<Skeleton w="full" h="200px" borderRadius="lg" />
						<Skeleton w="full" h="200px" borderRadius="lg" />
						<Skeleton w="full" h="200px" borderRadius="lg" />
					</SimpleGrid>
				) : employeeList.data.data.list.length === 0 ? (
					<Box>
						<Image src="/assets/images/not_found.png" h="150px" />
						<Text as="p" m="0 auto" textAlign="center" color="gray.600" fontWeight="bold">
							Karyawan {keyword} tidak ditemukan
						</Text>
					</Box>
				) : (
					<SimpleGrid columns={[1, null, 3]} gap={4} w="full">
						{employeeList.data.data.list.map(employee => (
							<EmployeeSelectBox
								value={`${employee.ID}`}
								key={employee.ID}
								employee={employee}
								selected={selectedEmployee === `${employee.ID}`}
								onChange={value => {
									setSelectedEmployee(value)
									setValue('employeeID', value, {
										shouldDirty: true,
										shouldValidate: true,
									})
									if (inputRef.current) {
										inputRef.current.focus()
									}
								}}
							/>
						))}
					</SimpleGrid>
				)}

				<Box textAlign="left" w="full">
					<Text color="red.400" fontSize="sm">
						{errors?.employeeID?.message}
					</Text>
				</Box>

				<FormControl id="guestInstitution" isInvalid={!!errors?.need}>
					<FormLabel color="gray.800">
						Kebutuhan <span style={{ color: 'red' }}>*</span>
					</FormLabel>
					<Textarea
						fontWeight="medium"
						bg="gray.200"
						ref={inputRef}
						color="gray.700"
						border="none"
						name="need"
						type="text"
						placeholder="Masukan Kebutuhan Anda"
					/>
					<FormErrorMessage>{errors?.need?.message}</FormErrorMessage>
				</FormControl>

				<FormControl id="guestInstitution" isInvalid={!!errors?.dateTime}>
					<FormLabel color="gray.800">
						Pilih Tanggal dan Jam <span style={{ color: 'red' }}>*</span>
					</FormLabel>
					<SimpleGrid columns={[1, null, 2]} spacing="4">
						<DatePicker autoOk variant="static" openTo="date" value={date} onChange={changeDate} />
						<TimePicker autoOk variant="static" onChange={changeDate} ampm={false} value={date} />
					</SimpleGrid>
					<FormErrorMessage>{errors?.dateTime?.message}</FormErrorMessage>
				</FormControl>

				<Button
					marginTop="32px !important"
					onClick={handleSubmit(onSubmit)}
					isLoading={useCreateMeeting.isLoading}
					loadingText="Memproses"
					bg="pink.500"
					w="full"
					size="lg"
					_hover={{ bg: 'pink.600' }}
					_active={{
						bg: 'pink.500',
					}}
					_focus={{
						outline: 'none',
					}}>
					Lanjut
				</Button>
			</VStack>
		</VStack>
	)
}

export default StepTwo

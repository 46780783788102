import { Heading, Center, VStack, Box, Text, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const HomePage = () => {
	return (
		<Center w="100vw" h="100vh" color="gray.50" className="guest-container">
			<VStack spacing={6}>
				<Box>
					<Heading as="h2" size="3xl">
						Selamat Datang
					</Heading>
				</Box>
				<Box>
					<Text fontSize="xl">Untuk memulai, silahkan tekan tombol dibawah</Text>
				</Box>
				<Box>
					<Link to="/guest">
						<Button
							bg="pink.500"
							w="200px"
							size="lg"
							_hover={{ bg: 'pink.600' }}
							_active={{
								bg: 'pink.500',
							}}
							_focus={{
								outline: 'none',
							}}>
							Mulai
						</Button>
					</Link>
				</Box>
			</VStack>
		</Center>
	)
}

export default HomePage
